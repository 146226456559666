import { OutlinedInput, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { shouldForwardProp } from "@mui/system";

export const HeaderSearchInputOutlineInputStyle = styled(OutlinedInput, {
  shouldForwardProp,
})(({ theme }) => ({
  width: 434,
  marginLeft: 16,
  paddingLeft: 16,
  paddingRight: 16,
  "& input": {
    background: "transparent !important",
    paddingLeft: "4px !important",
  },
  [theme.breakpoints.down("lg")]: {
    width: 250,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 4,
    background: "#fff",
  },
}));

export const HeaderSearchInputPopperStyle = styled(Popper, {
  shouldForwardProp,
})(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));
