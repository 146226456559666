import PropTypes from "prop-types";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useTheme,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavGroup: FC<any> = ({ item, showIcon }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(true);

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    hasChildren: boolean
  ) => {
    if (hasChildren) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen((prev) => !prev);
  };

  return (
    <>
      <NavLink to={item.url} end={!!item.children}>
        {({ isActive }) => (
          <StyledListItemButton
            onClick={(e) => handleClick(e, !!item.children)}
            selected={isActive}
          >
            {item.icon && (
              <ListItemIcon sx={{ justifyContent: "center" }}>
                <item.icon />
              </ListItemIcon>
            )}
            <StyledListItemText
              primary={t(item.title)}
              sx={{
                visibility: showIcon ? "hidden" : "visible",
                opacity: showIcon ? "0" : "1",
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
                marginLeft: showIcon ? 0 : 1,
              }}
            />
            {item.children && (open ? <ExpandLess /> : <ExpandMore />)}
          </StyledListItemButton>
        )}
      </NavLink>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child: any) => (
              <NavLink to={child.url} key={child.id}>
                {({ isActive }) => (
                  <StyledListItemButton sx={{ pl: 4 }} selected={isActive}>
                    <StyledListItemText primary={t(child.title)} />
                  </StyledListItemButton>
                )}
              </NavLink>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;

// styles
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  "&.MuiListItemText-root .MuiListItemText-primary": {
    color: "inherit",
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  justifyContent: "center",
  "&.MuiListItemButton-root.Mui-selected": {
    backgroundColor: "unset",
    "& .MuiTypography-root": {
      fontWeight: 600,
    },
  },
}));
