//assets
import PortraitIcon from "@mui/icons-material/Portrait";

const membersPage = {
  id: "members_page",
  title: "members",
  type: "item",
  icon: PortraitIcon,
  url: "/members",
};

export default membersPage;
