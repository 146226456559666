import { lazy } from "react";

// project imports
import Loadable from "@components/shared/Loadable";

// route
const Login = Loadable(lazy(() => import("@pages/Login")));

const AuthenticationRoutes = {
  path: "login",
  element: <Login />,
};

export default AuthenticationRoutes;
