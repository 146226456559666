import { FC } from "react";
import Loading from "./Loading";
import { useAtomValue } from "jotai";
import { loadingAtom } from "@/jotai/layout";

const FullPageLoading: FC = () => {
  const loading = useAtomValue(loadingAtom);

  return <Loading open={loading} />;
};

export default FullPageLoading;
