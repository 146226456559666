// assets
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';

const cityPage = {
  id: "city_page",
  title: "city",
  type: "item",
  icon: LocationCityRoundedIcon,
  url: "/city",
};

export default cityPage;
