import { langaugesKey } from "@/interfaces/general";
import { IEnqueueSnackbar } from "@/interfaces/layout";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const leftDrawerOpenedAtom = atom(true);

export const darkModeAtom = atomWithStorage("disalone_panel_dark_mode", false);

export const snackbarAtom = atom<IEnqueueSnackbar>({
  open: false,
  message: "",
});

export const loadingAtom = atom(false);

export const languageAtom = atomWithStorage<langaugesKey>(
  "disalone_panel_language",
  "en"
);
