import ViewListIcon from "@mui/icons-material/ViewList";

const logsPage = {
  id: "log_page",
  title: "logs",
  type: "item",
  icon: ViewListIcon,
  url: "/logs",
};

export default logsPage;
