import { List } from "@mui/material";
import NavGroup from "./MenuList/NavGroup";
import menuItems from "./MenuList/sideMenuItems";
import { FC } from "react";

const MenuList: FC<{ drawerOpen: boolean }> = ({ drawerOpen }) => {
  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
    >
      {menuItems.map((item) => (
        <NavGroup key={item.id} item={item} showIcon={!drawerOpen} />
      ))}
    </List>
  );
};

export default MenuList;
