import { IComponentWithChildren } from "@/interfaces/general";
import { profileAtom } from "@/jotai/profile";
import { CircularProgress } from "@mui/material";
import { useSetAtom } from "jotai";
import Cookies from "js-cookie";
import { FC, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import store from "store";

const ProtectedRoutes: FC<IComponentWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const PROFILE = store.get("disalone_panel_user");
  const TOKEN = Cookies.get("disalone_panel_token");

  const setProfile = useSetAtom(profileAtom);

  useEffect(() => {
    if (!!TOKEN && !!PROFILE) setProfile(PROFILE);
    setLoading(false);
  }, [PROFILE, TOKEN]);

  if (loading) return <CircularProgress />;

  if (!loading && !TOKEN) {
    store.clearAll();
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoutes;
