import dashboard from "./dashboard";
import currencyPage from "./currency";
import countryPage from "./country";
import cityPage from "./city";
import categoryPage from "./category";
import membersPage from "./members";
import logsPage from "./logs";
import contentsPage from "./contents";

const menuItems = [
  dashboard,
  currencyPage,
  countryPage,
  cityPage,
  categoryPage,
  membersPage,
  logsPage,
  contentsPage,
];

export default menuItems;
