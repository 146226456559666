import { FC } from "react";
import { Modal, ModalProps, keyframes, styled } from "@mui/material";

const load = keyframes`
       0%{
            transform: rotate(0deg);
        }
        10%{
            transform: rotate(45deg);
        }
        50%{
            opacity: 1;
            transform: rotate(160deg);
        }
        62%{
            opacity: 0;
        }
        65%{
            opacity: 0;
            transform: rotate(200deg);
        }
        90%{
            transform: rotate(340deg);
        }
        100%{
            transform: rotate(360deg);
        }
`;

const Container = styled("div")`
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const ItemDiv = styled("div")`
  width: 100%;
  height: 100%;
  position: absolute;
  animation: ${load} 2s linear infinite;
`;

const ItemDiv1 = styled(ItemDiv)`
  animation-delay: 0.2s;
`;

const ItemDiv2 = styled(ItemDiv)`
  animation-delay: 0.4s;
`;

const ItemDiv3 = styled(ItemDiv)`
  animation-delay: 0.6s;
`;

const ItemDiv4 = styled(ItemDiv)`
  animation-delay: 0.8s;
`;

const ItemSpan = styled("span")(
  ({ theme }) =>
    `
        display: inline-block;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: ${theme.palette.secondary.main};
        position: absolute;
        left: 50%;
        margin-top: -10px;
        margin-left: -10px;
    `
);
const Loading: FC<Omit<ModalProps, "children">> = (props) => {
  return (
    <Modal
      {...props}
      slotProps={{
        backdrop: {
          style: {
            opacity: "0.5",
          },
        },
      }}
    >
      <Container>
        <ItemDiv1>
          <ItemSpan />
        </ItemDiv1>
        <ItemDiv2>
          <ItemSpan />
        </ItemDiv2>
        <ItemDiv3>
          <ItemSpan />
        </ItemDiv3>
        <ItemDiv4>
          <ItemSpan />
        </ItemDiv4>
      </Container>
    </Modal>
  );
};

export default Loading;
