// assets
import CategoryIcon from "@mui/icons-material/Category";

const categoryPage = {
  id: "categor_page",
  title: "Category",
  type: "item",
  icon: CategoryIcon,
  url: "/category",
};

export default categoryPage;
