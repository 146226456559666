import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles, StyledEngineProvider } from "@mui/material";
import NavigationScroll from "@components/shared/NavigationScroll";
import { I18nextProvider } from "react-i18next";
import i18next from "@/i18n/config";
import { theme } from "@/styles/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import globalStyles from "@/styles/globalStyles";
import Snackbar from "@/layout/Snackbar";
import FullPageLoading from "@components/shared/FullPageLoading";

// jotai
import { useAtomValue } from "jotai";
import { darkModeAtom } from "@/jotai/layout";

// routing
import Routes from "@/routes";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const darkMode = useAtomValue(darkModeAtom);

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18next}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme(darkMode)}>
            <GlobalStyles styles={globalStyles as any} />
            <CssBaseline />
            <Snackbar />
            <FullPageLoading />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default App;
