const darkColors = {
  paper: "#111936",

  // primary
  primaryLight: "#1a223f",
  primaryMain: "#2196f3",
  primaryDark: "#1e88e5",
  primary200: "#90caf9",
  primary800: "#1565c0",

  // secondary
  secondaryLight: "#7c4dff15",
  secondaryMain: "#673ab7",
  secondaryDark: "#7c4dff",
  secondary200: "#b39ddb",
  secondary800: "#4527a0",

  // success Colors
  successLight: "#b9f6ca",
  success200: "#69f0ae",
  successMain: "#00e676",
  successDark: "#00c853",

  // error
  errorLight: "#ef9a9a",
  errorMain: "#f44336",
  errorDark: "#c62828",

  // orange
  orangeLight: "#fbe9e7",
  orangeMain: "#ffab91",
  orangeDark: "#d84315",

  // warning
  warningLight: "#fff8e1",
  warningMain: "#ffe57f",
  warningDark: "#ffc107",

  // grey
  grey50: "#1a223f",
  grey100: "#1a223f",
  grey200: "#E3E8EF",
  grey300: "#CDD5DF",
  grey500: "#697586",
  grey600: "#4B5565",
  grey700: "#bdc8f0",
  grey900: "#d7dcec",

  // paper & background
  darkBackground: "#1a223f", // level 3
  darkPaper: "#111936", // level 4

  // dark 800 & 900
  darkLevel1: "#29314f", // level 1
  darkLevel2: "#212946", // level 2

  // primary dark
  darkPrimaryLight: "#eef2f6",
  darkPrimaryMain: "#2196f3",
  darkPrimaryDark: "#1e88e5",
  darkPrimary200: "#90caf9",
  darkPrimary800: "#1565c0",

  // secondary dark
  darkSecondaryLight: "#d1c4e9",
  darkSecondaryMain: "#7c4dff",
  darkSecondaryDark: "#651fff",
  darkSecondary200: "#b39ddb",
  darkSecondary800: "#6200ea",

  // text variants
  darkTextTitle: "#d7dcec",
  darkTextPrimary: "#bdc8f0",
  darkTextSecondary: "#8492c4",
};

export default darkColors;
