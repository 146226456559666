import { lazy } from "react";

// project imports
import Loadable from "@components/shared/Loadable";

// route
const Error = Loadable(lazy(() => import("@pages/Error")));

const ErrorRoute = {
  path: "*",
  element: <Error />,
};

export default ErrorRoute;
