import { FC, lazy, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, Chip, styled } from "@mui/material";
import { ITheme } from "@/interfaces/themeProps";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import Loadable from "@components/shared/Loadable";
import { useAtomValue } from "jotai";
import { profileAtom } from "@/jotai/profile";

const ProfileMenu = Loadable(
  lazy(() => import("./ProfileSection/ProfileMenu"))
);

const ProfileSection: FC = () => {
  const theme = useTheme() as ITheme;
  const profile = useAtomValue(profileAtom);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <>
      <StyledChip
        icon={
          profile?.avatar ? (
            <Avatar src={profile.avatar} sizes="32px" />
          ) : (
            <AccountCircleIcon
              sx={{
                width: 32,
                height: 32,
              }}
            />
          )
        }
        label={
          <SettingsIcon
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        }
        variant="outlined"
        aria-describedby={id}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
      />
      <ProfileMenu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
      />
    </>
  );
};

export default ProfileSection;

// styles
const StyledChip = styled(Chip)(({ theme }) => ({
  height: "48px",
  alignItems: "center",
  borderRadius: "27px",
  transition: "all .2s ease-in-out",
  borderColor: theme.palette.primary.light,
  backgroundColor: theme.palette.primary.light,
  '&[aria-controls="menu-list-grow"], &:hover': {
    borderColor: theme.palette.primary.main,
    background: `${theme.palette.primary.main}!important`,
    color: theme.palette.primary.light,
    "& svg": {
      stroke: theme.palette.primary.light,
    },
  },
  "& .MuiChip-label": {
    lineHeight: 0,
  },
}));
