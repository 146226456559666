import {
  Avatar,
  Box,
  ButtonBase,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { HeaderSearchInputOutlineInputStyle } from "../headerSearchInputStyles";
import IconX from "@mui/icons-material/CloseRounded";
import { ITheme } from "@/interfaces/themeProps";
import SearchIcon from "@mui/icons-material/SearchRounded";
import { bindToggle } from "material-ui-popup-state";

interface IHeaderMobileSearch {
  value: string;
  setValue: Function;
  popupState: any;
}

const HeaderMobileSearch: FC<IHeaderMobileSearch> = (props) => {
  const { value, setValue, popupState } = props;
  const theme = useTheme() as ITheme;

  return (
    <HeaderSearchInputOutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background: theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  "&:hover": {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light,
                  },
                }}
                {...bindToggle(popupState)}
              >
                <IconX />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

export default HeaderMobileSearch;
