//assets
import PublicIcon from "@mui/icons-material/Public";

const countryPage = {
  id: "country_page",
  title: "country",
  type: "item",
  icon: PublicIcon,
  url: "/country",
};

export default countryPage;
