// assets
import OtherHousesRoundedIcon from "@mui/icons-material/OtherHousesRounded";

const dashboard = {
  id: "dashboard",
  title: "home",
  type: "item",
  url: "/",
  icon: OtherHousesRoundedIcon,
};

export default dashboard;
