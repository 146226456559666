import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import ErrorRoute from "./ErrorRoute";

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, AuthenticationRoutes, ErrorRoute]);
}
