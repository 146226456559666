import { FC, useState } from "react";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
} from "@mui/material";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import { shouldForwardProp } from "@mui/system";
import { ITheme } from "@/interfaces/themeProps";
import Transitions from "@components/shared/Transition";
import { useTheme, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/SearchRounded";
import {
  HeaderSearchInputOutlineInputStyle,
  HeaderSearchInputPopperStyle,
} from "./headerSearchInputStyles";
import HeaderMobileSearch from "./HeaderSearchInput/HeaderMobileSearch";

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }: { theme: ITheme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  })
);

const HeaderSearchInput: FC = () => {
  const theme = useTheme();
  const [value, setValue] = useState("");

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: "12px" }}>
                  <HeaderAvatarStyle
                    variant="rounded"
                    theme={theme}
                    {...bindToggle(popupState)}
                  >
                    <SearchIcon />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <HeaderSearchInputPopperStyle
                {...bindPopper(popupState)}
                transition
              >
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background: "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <HeaderMobileSearch
                                value={value}
                                setValue={setValue}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </HeaderSearchInputPopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <HeaderSearchInputOutlineInputStyle
          id="input-search-header"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        />
      </Box>
    </>
  );
};

export default HeaderSearchInput;
