import { snackbarAtom } from "@/jotai/layout";
import {
  Alert,
  IconButton,
  Slide,
  Snackbar as MuiSnackbar,
} from "@mui/material";
import { useAtom } from "jotai";
import { FC, useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Snackbar: FC = () => {
  const [snackbar, setSnackbar] = useAtom(snackbarAtom);
  const [_open, setOpen] = useState(snackbar.open);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!!snackbar.open) setOpen(true);
  }, [snackbar.open]);

  useEffect(() => {
    if (!_open) {
      setTimeout(() => {
        setSnackbar({
          open: false,
          message: "",
        });
      }, 150);
    }
  }, [_open]);

  const action = useMemo(
    () => (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ),
    [snackbar]
  );

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={_open}
      onClose={handleClose}
      autoHideDuration={4000}
      TransitionComponent={Slide}
      disableWindowBlurListener
      ClickAwayListenerProps={{
        onClickAway: () => { }
      }}
    >
      <Alert severity={snackbar.options?.variant ?? "success"} action={action} variant="filled">
        {snackbar.message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
