import HeaderSearchInput from "./Header/HeaderSearchInput";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, Stack, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FC } from "react";
import { IHeader } from "@/interfaces/layout";
import { ITheme } from "@/interfaces/themeProps";
import ProfileSection from "./Header/ProfileSection";
import LanguageSelector from "@components/shared/LanguageSelector";

const Header: FC<IHeader> = ({ handleLeftDrawerToggle, drawerOpen }) => {
  const theme = useTheme() as ITheme;
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <ButtonBase
          sx={{
            borderRadius: "12px",
            overflow: "hidden",
            marginLeft: drawerOpen || !matchUpMd ? 0 : "-18px",
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Avatar
            component="div"
            variant="rounded"
            sx={{
              // ...theme.typography.commonAvatar,
              // ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={() => handleLeftDrawerToggle()}
            color="inherit"
          >
            <MenuIcon />
          </Avatar>
        </ButtonBase>
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          {/* <LogoSection /> */}
        </Box>
      </Box>

      {/* header search */}
      <HeaderSearchInput />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" gap={2}>
        <LanguageSelector />
        {/* profile */}
        <ProfileSection />
      </Stack>
    </>
  );
};

export default Header;
