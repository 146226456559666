import CodeIcon from '@mui/icons-material/Code';


const contentsPage = {
    id: "content_page",
    title: "contents",
    type: "item",
    icon: CodeIcon,
    url: "/content",
};

export default contentsPage;
