// assets
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

const currencyPage = {
  id: "currency_page",
  title: "currency",
  type: "item",
  icon: AttachMoneyRoundedIcon,
  url: "/currency",
};

export default currencyPage;
