import { LanguageObject, langaugesKey } from "@/interfaces/general";
import { languageAtom } from "@/jotai/layout";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useAtom(languageAtom);
  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as langaugesKey);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <Select
      defaultValue="en"
      onChange={handleChange}
      value={language}
      color="secondary"
      sx={{
        fontSize: "1rem",
        "& .MuiSelect-select": {
          p: "12px",
        },
      }}
      variant="standard"
      disableUnderline
    >
      {languages.map((lng) => (
        <MenuItem key={lng.key} value={lng.key} sx={{ fontSize: "1rem" }}>
          <div dangerouslySetInnerHTML={{ __html: `${lng.value}` }} />
        </MenuItem>
      ))}
    </Select>
  );
};
export default LanguageSelector;

// languages list
// if you want to add another language
// you should first add that new language key to the ```langaugesKey``` type
const languages: LanguageObject[] = [
  {
    key: "en",
    value: "English &#x1F1FA;&#x1F1F8;",
  },
  {
    key: "tr",
    value: "Turkish &#x1F1F9;&#x1F1F7;",
  },
];
